import moment from 'moment'
import { isValidValue, isValidNumber } from 'helpers/values'
import { formatCurrencyWithCountryCode } from 'helpers/currency'
import { snakeCaseToCapitalizedWords } from 'helpers/strings'
import { humanizeLongNumberWithLocale } from 'helpers/formatters'

import { WEIGHT_UNITS, DIMENSION_UNITS } from '@junglescout/amazon-calculator'

export const isProductSyncInProgress = (selectedAccount, mwsSync) => {
  if (
    !mwsSync ||
    !Array.isArray(mwsSync.status.amazon_seller_accounts) ||
    !selectedAccount
  ) {
    return false
  }

  const syncProgress = mwsSync.status.amazon_seller_accounts.find(
    a => a.id === selectedAccount.id
  )

  return !!(
    syncProgress &&
    syncProgress.mws_product != null &&
    syncProgress.mws_product < 100
  )
}

export const productImageResize = (url, size = 280) => {
  return url && url.replace('75_.', `${size}_.`)
}

export const parseVariantProductDifferences = product => {
  const { dimensionValuesDisplayData, asin } = product

  const variantObj = dimensionValuesDisplayData?.find(
    variant => variant.asin === asin
  )

  return variantObj?.differences
}

const formatDimensions = product => {
  const { width, height, length, dimensionUnit } = product
  const isValid =
    isValidNumber(width) &&
    isValidNumber(height) &&
    isValidNumber(length) &&
    (width > 0 || height > 0 || length > 0)
  const unit = dimensionUnit?.toLowerCase() || 'in'
  if (isValid) {
    const measure =
      DIMENSION_UNITS.find(o => o.variants.includes(dimensionUnit))?.unit ||
      unit
    const formattedLength = length?.toLocaleString() || '0'
    const formattedWidth = width?.toLocaleString() || '0'
    const formattedHeight = height?.toLocaleString() || '0'
    return `${formattedLength} x ${formattedWidth} x ${formattedHeight} ${measure}`
  }

  return undefined
}

const formatWeight = product => {
  const { weight, weightUnit } = product
  const unit = weightUnit?.toLowerCase() || 'lbs'

  const measure =
    WEIGHT_UNITS.find(o => o.variants.includes(unit))?.unit || unit
  return `${weight?.toLocaleString()} ${measure}`
}

/**
 * Formats any product attribute
 * @param {*} attribute - The key of the attribute to format
 * @param {*} product - The whole product object
 * @returns A formatted attribute or undefined if the value is invalid
 */
export const formatProductAttribute = (attribute, product) => {
  if (!product) return undefined

  const value = product[attribute]

  // Special cases (Where the attribute is not a direct key in the product object)
  if (attribute === 'dimensions') {
    return formatDimensions(product)
  }

  if (attribute === 'variantDifferences') {
    if (product.isVariant)
      return product.dimensionValuesDisplayData?.[0]?.differences?.join?.(', ')

    return product.dimensions?.map?.(snakeCaseToCapitalizedWords).join?.(', ')
  }

  if (attribute === 'nSellers') {
    return value || 1 // Default to 1 if no value
  }

  if (!isValidValue(value)) return undefined

  if (typeof value === 'string' && (value === 'N/A' || value.length === 0))
    return undefined

  if (typeof value === 'number' && value <= 0) return undefined

  const { country } = product

  switch (attribute) {
    case 'name':
      return value.replace(/,/g, ' ')
    case 'estRevenue':
    case 'price':
    case 'fees':
    case 'net':
      return formatCurrencyWithCountryCode(value, country)
    case 'rank':
    case 'estimatedSales':
    case 'nReviews':
      return humanizeLongNumberWithLocale(value)
    case 'listingQualityScore': {
      const number = Number.isNaN(value) ? 0 : parseInt(value, 10)
      return number > 0 ? Math.round(number / 10) : undefined
    }
    case 'listedAt':
    case 'estimatedListedAt':
    case 'scrapedAt':
      return moment(value).format('MM/DD/YYYY')
    case 'rating':
      return parseFloat(value).toFixed(1)
    case 'weight': {
      return formatWeight(product)
    }
    default:
      return product[attribute]
  }
}
